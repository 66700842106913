import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

import {RequestActionTypes} from "@pg-mono/request-state";

import {fetchPropertiesDetailByIds} from "../api/fetch_properties_detail_by_ids";
import {IPropertyDetail} from "../types/IPropertyDetail";

type IPropertiesDetails = {[key in string]: IPropertyDetail};
export const useFetchPropertiesDetails = (propertyIds?: number[], action?: RequestActionTypes) => {
    const [propertiesDetails, setPropertiesDetails] = useState<IPropertiesDetails>({});
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (Array.isArray(propertyIds) && propertyIds.length > 0) {
            setIsLoading(true);
            if (action) {
                dispatch({type: action.start});
            }

            fetchPropertiesDetailByIds({}, propertyIds, true)
                .then((response) => {
                    const propertiesDetails = response.results.reduce((acc, property) => {
                        acc[property.id] = property;
                        return acc;
                    }, {} as IPropertiesDetails);
                    setPropertiesDetails(propertiesDetails);

                    if (action) {
                        dispatch({type: action.success, result: propertiesDetails});
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [propertyIds?.join(",")]);

    return {
        propertiesDetails,
        isLoading
    };
};
