import {IOfferDetail} from "../types/IOfferDetail";

/**
 *  New PH (Panel Handlowy) status is determined by more complicated, business logic.
 *  This function is to align api values with values and names, used by business.
 *  More details can be found in this task - https://app.clickup.com/t/862j2xxf3
 *
 *  For instance: "limited" is a status that tells us that the offer is still for sale, but it reached the lead limit,
 *  hence it should have all form application triggers hidden (application forms, ask for price buttons, phone number etc.)
 */

export type IOfferDisplayStatus = "hidden" | "visible" | "limited";

export type IOfferDisplayStatusData = Pick<IOfferDetail, "is_active"> & {configuration: Pick<IOfferConfiguration["configuration"], "limited_presentation">};

type IOfferConfiguration = Pick<IOfferDetail, "configuration">;

export function getOfferDisplayStatus(offer: IOfferDisplayStatusData): IOfferDisplayStatus {
    if (offer.configuration.limited_presentation) {
        return "limited";
    }

    if (offer.is_active) {
        return "visible";
    }

    return "hidden";
}
